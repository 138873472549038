import React, { useMemo } from "react";
import { Box, Button, Divider, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { checkIfQuillValueIsEmpty } from "../../../../../utils";
import { ReadOnlyQuill } from "../catalogDetails/ReadOnlyQuill";
import { catalogDetailsKeysToRenderAsSection, catalogDetailsToRenderAsLinkConfig } from "modules/desking/constants";
import { ModalsKeys } from "components/shared/Modals";
import { useModal } from "hooks/useModal";
import { CatalogDetailsToRenderAsLinkConfig } from "modules/desking/types";
import { ProposalMenuOption, ProposalProduct, ProposalProductAddon } from "@trnsact/trnsact-shared-types";

interface Props {
  menuOption: ProposalMenuOption;
  product: ProposalProduct;
}

export const MenuOptionProductDescriptions = ({ menuOption, product }: Props) => {
  const classes = useStyles();

  const { handleOpen, handleClose } = useModal(ModalsKeys.Confirmation);

  const aftermarketProductCatalogDetails = (product as any)?.aftermarketProduct?.aftermarketProductCatalogDetails;

  const { catalogDetailsKeysToRenderAsSectionWithData, catalogDetailsToRenderAsLinkWithData } = useMemo(
    () => ({
      catalogDetailsKeysToRenderAsSectionWithData: catalogDetailsKeysToRenderAsSection.filter(
        key => !checkIfQuillValueIsEmpty(aftermarketProductCatalogDetails[key])
      ),
      catalogDetailsToRenderAsLinkWithData: catalogDetailsToRenderAsLinkConfig.filter(
        config => !checkIfQuillValueIsEmpty(aftermarketProductCatalogDetails[config.key])
      ),
    }),
    [product]
  );

  const handleCatalogDetailsModalOpen = (config: CatalogDetailsToRenderAsLinkConfig) => {
    handleOpen({
      title: config.label,
      message: <ReadOnlyQuill value={aftermarketProductCatalogDetails[config.key]} />,
      actionButtons: (
        <Button className={classes.closeButton} size="small" color="primary" variant="outlined" onClick={handleClose}>
          Close
        </Button>
      ),
    });
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h6">{product.title}</Typography>
      {catalogDetailsKeysToRenderAsSectionWithData.map((key, idx) => (
        <Box key={key} mt={1} mb={1}>
          {aftermarketProductCatalogDetails[key] && (
            <>
              <ReadOnlyQuill value={aftermarketProductCatalogDetails[key]} />
              {idx !== catalogDetailsKeysToRenderAsSection.length - 1 && (
                <Box mt={1}>
                  <Divider />
                </Box>
              )}
            </>
          )}
        </Box>
      ))}

      {!!catalogDetailsToRenderAsLinkWithData.length && (
        <>
          <Box mt={1} mb={1}>
            <Divider />
          </Box>
          {catalogDetailsToRenderAsLinkWithData.map((config, idx) => (
            <React.Fragment key={config.label}>
              <Link href="#" component="button" onClick={() => handleCatalogDetailsModalOpen(config)}>
                <Typography component="span">{config.label}</Typography>
              </Link>
              {idx !== catalogDetailsToRenderAsLinkWithData.length - 1 && " | "}
            </React.Fragment>
          ))}
        </>
      )}

      {(product as any).addons?.map((addon: ProposalProductAddon) => (
        <Box key={addon.title} display="flex" mt={0.5} mb={0.5}>
          <CheckCircleIcon style={{ color: menuOption.titleBgColor!, marginRight: 4 }} />
          <Typography variant="subtitle2">{addon.title}</Typography>
        </Box>
      ))}
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    padding: 8,
    margin: "8px 0",
    backgroundColor: "#F5F5F5",
  },
  closeButton: {
    margin: "0 auto",
  },
});
