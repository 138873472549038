import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import * as moment from "moment";
import { Fields } from "./Fields";
import { deskingSelectors } from "../../../../../model";
import { vendorsLogoByType } from "../../../../../constants";
import { ProposalCardContainer } from "../ProposalCardContainer";
import {
  CommonMenuPriceValues,
  FinanceProgramFormValues,
  MarkupType,
  ProposalProductCardProps,
} from "../../../../../types";
import {
  GaigAvailableAsset,
  GaigCreateContractOptions,
  GaigCreateContractResponse,
  GaigProgramCode,
} from "@trnsact/aftermarket-vendor-sdk-gaig";
import { GET_DYNAMIC_AFTERMARKET_PRODUCT_PRICING } from "modules/desking/api/getAftermarketProductDynamicPricing";
import { AftermarketProductVendorApiChannel } from "@trnsact/trnsact-shared-types";
import Delta from "quill-delta";
import { useFormContext, useWatch } from "react-hook-form";
import { formatCurrency } from "utils";

const GAIG_CATEGORIES = [
  {
    category: "Compact Construction",
    assetClass: "G2",
  },
  {
    category: "Heavy Construction",
    assetClass: "G1",
  },
  {
    category: "Lifts",
    assetClass: "G3",
  },
  {
    category: "Portable Construction",
    assetClass: "I4",
  },
  {
    category: "Utility Construction",
    assetClass: "G6",
  },
  {
    category: "Class 1-2",
    assetClass: "V1",
  },
  {
    category: "Class 3-5",
    assetClass: "V2",
  },
  {
    category: "Class 6-7",
    assetClass: "V4",
  },
  {
    category: "Class 8",
    assetClass: "V6",
  },
  {
    category: "Utility Trailer",
    assetClass: "V7",
  },
  {
    category: "Trailer",
    assetClass: "V8",
  },
  {
    category: "Semi-Trailer",
    assetClass: "V9",
  },
];

export const Card = ({
  mode,
  product,
  menuName = "",
  type = "simple",
  updateProductConfiguration,
}: ProposalProductCardProps) => {
  const [isDynamicPricingLoading, setLoadingDynamicPricing] = useState(false);

  const formContext = useFormContext<FinanceProgramFormValues>();
  const control = formContext?.control;
  const financeQuote = control ? useWatch<FinanceProgramFormValues>({ control }).financeQuote : undefined;

  const proposalProductConfiguration = useSelector((state: any) =>
    deskingSelectors.productConfiguration(state, product.proposalProductId)
  ) as Record<
    | "programCode"
    | "selectedProgram"
    | "availableAsset"
    | "contractTerm"
    | "coverageTerm"
    | "billingFrequency"
    | "contractResponse",
    any
  >;

  const [forcePriceValue, setForcePriceValue] = useState<CommonMenuPriceValues>({
    retailCost: product.retailCost,
    cost: product.cost,
    markup: product.markup,
  });

  const createProductSummaryDelta = (newConfiguration: any, finalRetailPricePerMonth: number) => {
    const productSummaryDelta = new Delta();
    const items = [
      newConfiguration.selectedProgram.ProgramDescription,
      `Coverages: ${
        newConfiguration.contractResponse.data.portfolios
          ? newConfiguration.contractResponse.data.portfolios[0].coverages.join(", ")
          : ""
      }`,
      `${formatCurrency(finalRetailPricePerMonth, true)} per month`,
    ];
    items.forEach(item => {
      // productSummaryDelta.insert(`${item}\n`, { list: 'bullet' });
      productSummaryDelta.insert(`${item}\n`);
    });
    return productSummaryDelta;
  };

  const createProductCoverageDelta = (newConfiguration: any) => {
    const coverageDelta = new Delta();
    const bulletedItems = [
      newConfiguration.selectedProgram.ProgramDescriptionExternal,
      `Contract Dates: ${newConfiguration.contractResponse.data.contractStartDate} to ${newConfiguration.contractResponse.data.contractEndDate}`,
      `Coverage Term: ${configuration.coverageTerm} months`,
      `Quote ID: ${newConfiguration.contractResponse.data.quoteID}`,
    ];
    bulletedItems.forEach(item => {
      coverageDelta.insert(`${item}\n`, { list: "bullet" });
    });
    return coverageDelta;
  };

  const [getDynamicPricing] = useLazyQuery<
    { aftermarketProductDynamicPricing: { success: boolean; errorMessage?: string; data: GaigCreateContractResponse } },
    { input: { aftermarketVendorApiChannel: AftermarketProductVendorApiChannel; gaigData: GaigCreateContractOptions } }
  >(GET_DYNAMIC_AFTERMARKET_PRODUCT_PRICING, {
    onCompleted(response) {
      if (response.aftermarketProductDynamicPricing.success) {
        const totalMonthlyAmount = response?.aftermarketProductDynamicPricing?.data?.totalMonthlyAmount || 0;
        const totalFeePerInvoice = response?.aftermarketProductDynamicPricing?.data?.totalFeePerInvoice || 0;

        const finalRetailPricePerMonth = totalMonthlyAmount + totalFeePerInvoice;

        setConfiguration(prev => {
          const nextConfiguration = {
            ...prev,
            contractResponse: response?.aftermarketProductDynamicPricing,
          };

          if (type === "forEdit" && updateProductConfiguration) {
            updateProductConfiguration(product.proposalProductId, menuName, "configuration", nextConfiguration);
          }

          const productSummaryDelta = createProductSummaryDelta(nextConfiguration, finalRetailPricePerMonth);
          try {
            product.aftermarketProduct!.aftermarketProductCatalogDetails!.productSummary = productSummaryDelta;
          } catch (e) {
            console.error("Error updating summary details", e);
          }

          const coverageDelta = createProductCoverageDelta(nextConfiguration);
          try {
            product.aftermarketProduct!.aftermarketProductCatalogDetails!.coverageDetails = coverageDelta;
          } catch (e) {
            console.error("Error updating coverage details", e);
          }

          return nextConfiguration;
        });
        setForcePriceValue({
          cost: finalRetailPricePerMonth,
          retailCost: finalRetailPricePerMonth,
          markup: { type: MarkupType.Flat, markup: 0 },
        });

        setLoadingDynamicPricing(false);
      }
    },
    onError() {
      setLoadingDynamicPricing(false);
    },
  });

  const [configuration, setConfiguration] = useState<
    Record<
      | "programCode"
      | "selectedProgram"
      | "availableAsset"
      | "contractTerm"
      | "coverageTerm"
      | "billingFrequency"
      | "contractResponse",
      any
    >
  >({
    programCode: null,
    selectedProgram: null,
    availableAsset: null,
    contractTerm: null,
    coverageTerm: null,
    billingFrequency: null,
    contractResponse: null,
  });

  const data = useMemo<{ API_RESPONSE_DATA: { programCodes: GaigProgramCode[] } }>(
    () => product?.aftermarketProduct?.criteria?.[0]?.event?.params?.AFTERMARKET_PRODUCT_VENDOR_API_DATA,
    []
  );

  useEffect(() => {
    if (type !== "forEdit" || !proposalProductConfiguration) return;

    setConfiguration(proposalProductConfiguration);
  }, [proposalProductConfiguration]);

  const { uniqueAvailableAssets, filteredProgramCodes } = useMemo(() => {
    let uniqueAvailableAssets: GaigAvailableAsset[] = [];
    if (!data) {
      return {
        uniqueAvailableAssets,
        filteredProgramCodes: [],
      };
    }
    data.API_RESPONSE_DATA.programCodes.forEach(program => {
      program.availableAssets.forEach(asset => {
        uniqueAvailableAssets.push(asset);
      });
    });
    uniqueAvailableAssets = _.uniqBy(uniqueAvailableAssets, asset => asset.code);

    let filteredProgramCodes: GaigProgramCode[] = [];
    if (configuration.availableAsset) {
      data.API_RESPONSE_DATA.programCodes.forEach(program => {
        program.availableAssets.forEach(asset => {
          if (asset.code === configuration.availableAsset) {
            filteredProgramCodes.push(program);
          }
        });
      });
    } else {
      data.API_RESPONSE_DATA.programCodes.forEach(program => {
        filteredProgramCodes.push(program);
      });
    }
    filteredProgramCodes = _.uniqBy(filteredProgramCodes, program => program.ProgramCode);

    return {
      uniqueAvailableAssets,
      filteredProgramCodes,
    };
  }, [data, configuration]);

  useEffect(() => {
    if (!configuration.contractTerm || !configuration.coverageTerm) return;

    setLoadingDynamicPricing(true);

    let contractStartDate = moment.default();
    let contractEndDate = moment.default().add(configuration.contractTerm, "months");

    let coverageStartDate = moment.default();
    let coverageEndDate = moment.default().add(configuration.coverageTerm, "months");

    const assetCategory = GAIG_CATEGORIES.find(category => category.assetClass === configuration.availableAsset);

    getDynamicPricing({
      variables: {
        input: {
          aftermarketVendorApiChannel: AftermarketProductVendorApiChannel.Gaig,
          gaigData: {
            input: {
              contract: {
                billingFrequency: configuration.billingFrequency,
                contractStartDate: contractStartDate.toDate(),
                contractEndDate: contractEndDate.toDate(),
                coverageStartDate: coverageStartDate.toDate(),
                coverageEndDate: coverageEndDate.toDate(),
                contractNumber: "123456789",
                userName: "TRNSACT",
                customer: {
                  customerName: "ABC Hauling Inc.",
                  customerNumber: "some-customer-number",
                  customerAddress1: "123 Main St",
                  customerAddress2: "Suite 100",
                  customerCity: "Springfield",
                  customerStateProv: "OH",
                  customerPostalCode: "45201",
                  customerCountry: "USA",
                  customerContactEmail: "jsmith@example.com",
                  customerPhoneNumber: "(555) 555-5555",
                },
                vendor: {
                  vendorName: "Dealer ABC",
                  vendorNumber: "12345",
                },
                assets: [
                  {
                    category: assetCategory?.category || configuration.availableAsset,
                    assetAmount: financeQuote?.amount ? Number(financeQuote.amount) : 0,
                    programCode: configuration.programCode,
                  },
                ],
              },
            },
          },
        },
      },
    });
  }, [
    configuration.contractTerm,
    configuration.coverageTerm,
    configuration.availableAsset,
    configuration.selectedProgram,
  ]);

  const handleAvailableAssetChange = (selectedAsset: any) => {
    setConfiguration(prev => {
      const nextConfiguration = {
        ...prev,
        availableAsset: selectedAsset,
      };

      if (type === "forEdit" && updateProductConfiguration) {
        updateProductConfiguration(product.proposalProductId, menuName, "configuration", nextConfiguration);
      }
      return nextConfiguration;
    });
  };

  const handleSelectedProgramChange = (programCode: string) => {
    const selectedProgram = filteredProgramCodes.find(program => program.ProgramCode === programCode);
    setConfiguration(prev => {
      const nextConfiguration = {
        ...prev,
        programCode,
        selectedProgram,
      };

      if (type === "forEdit" && updateProductConfiguration) {
        updateProductConfiguration(product.proposalProductId, menuName, "configuration", nextConfiguration);
      }
      return nextConfiguration;
    });
  };

  const handleContractTermChange = (selectedContractTerm: number) => {
    setConfiguration(prev => {
      const nextConfiguration = {
        ...prev,
        contractTerm: selectedContractTerm,
      };

      if (type === "forEdit" && updateProductConfiguration) {
        updateProductConfiguration(product.proposalProductId, menuName, "configuration", nextConfiguration);
      }
      return nextConfiguration;
    });
  };

  const handleCoverageTermChange = (selectedCoverageTerm: number) => {
    setConfiguration(prev => {
      const nextConfiguration = {
        ...prev,
        coverageTerm: selectedCoverageTerm,
      };

      if (type === "forEdit" && updateProductConfiguration) {
        updateProductConfiguration(product.proposalProductId, menuName, "configuration", nextConfiguration);
      }
      return nextConfiguration;
    });
  };

  const handleBillingFrequencyChange = (selectedBillingFrequency: number) => {
    setConfiguration(prev => {
      const nextConfiguration = {
        ...prev,
        billingFrequency: selectedBillingFrequency,
      };

      if (type === "forEdit" && updateProductConfiguration) {
        updateProductConfiguration(product.proposalProductId, menuName, "configuration", nextConfiguration);
      }
      return nextConfiguration;
    });
  };

  return (
    <ProposalCardContainer
      type={type}
      mode={mode}
      menuName={menuName}
      proposalProduct={product}
      isPricingEditable={false}
      configuration={configuration}
      logo={vendorsLogoByType["GAIG"]}
      forcePriceValue={forcePriceValue}
      isDynamicPricingLoading={isDynamicPricingLoading}
      updateProductConfiguration={updateProductConfiguration}
    >
      <Fields
        configuration={configuration}
        uniqueAvailableAssets={uniqueAvailableAssets}
        filteredProgramCodes={filteredProgramCodes}
        handleAvailableAssetChange={handleAvailableAssetChange}
        handleSelectedProgramChange={handleSelectedProgramChange}
        handleContractTermChange={handleContractTermChange}
        handleCoverageTermChange={handleCoverageTermChange}
        handleBillingFrequencyChange={handleBillingFrequencyChange}
        isDynamicPricingLoading={isDynamicPricingLoading}
      />
    </ProposalCardContainer>
  );
};
