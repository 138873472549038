import React, { useEffect, useState } from "react";
import {
  FormControl,
  Divider,
  InputLabel,
  ListItemText,
  Button as MUIButton,
  MenuItem,
  LinearProgress,
  Select,
  Grid,
  TextField,
  Switch,
  makeStyles,
} from "@material-ui/core";
import _ from "lodash";
import ReactQuill from "react-quill";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import CustomCurrencyInput from "../../../components/CustomCurrencyInput";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  LENDER_WATERFALL_FACT_NAMES_BOOLEAN,
  LENDER_WATERFALL_FACT_NAMES_NUMERIC,
  LENDER_WATERFALL_FACT_NAMES_STRING,
  NUMERIC_OPERATORS,
  STRING_OPERATORS,
  ConditionSettingsNumeric,
  ConditionSettingsBoolean,
  ConditionSettingsString,
  LenderWaterfallConditions,
} from "@trnsact/types-lender-waterfall";
import {
  EQUIPMENT_CONDITION,
  FINANCING_TYPE,
  LEGAL_STRUCTURE,
  CANADA_REGION_CODES,
  US_REGION_CODES,
} from "@trnsact/trnsact-shared-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import DOMPurify from "dompurify";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ChipInput from "components/Generic/ChipInput/ChipInput";

interface FactNumericType {
  value: LENDER_WATERFALL_FACT_NAMES_NUMERIC;
  label: string;
}

interface FactBooleanType {
  value: LENDER_WATERFALL_FACT_NAMES_BOOLEAN;
  label: string;
}

interface FactStringType {
  value: LENDER_WATERFALL_FACT_NAMES_STRING;
  label: string;
}

const factNamesNumeric: FactNumericType[] = Object.keys(LENDER_WATERFALL_FACT_NAMES_NUMERIC).map(key => ({
  value: LENDER_WATERFALL_FACT_NAMES_NUMERIC[key as keyof typeof LENDER_WATERFALL_FACT_NAMES_NUMERIC],
  label: key
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b(\w)/g, char => char.toUpperCase()),
}));

const factNamesBoolean: FactBooleanType[] = Object.keys(LENDER_WATERFALL_FACT_NAMES_BOOLEAN).map(key => ({
  value: LENDER_WATERFALL_FACT_NAMES_BOOLEAN[key as keyof typeof LENDER_WATERFALL_FACT_NAMES_BOOLEAN],
  label: key
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b(\w)/g, char => char.toUpperCase()),
}));

const factNamesString: FactStringType[] = Object.keys(LENDER_WATERFALL_FACT_NAMES_STRING).map(key => ({
  value: LENDER_WATERFALL_FACT_NAMES_STRING[key as keyof typeof LENDER_WATERFALL_FACT_NAMES_STRING],
  label: key
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b(\w)/g, char => char.toUpperCase()),
}));

const numericOperatorNames = Object.keys(NUMERIC_OPERATORS).map(key => ({
  value: NUMERIC_OPERATORS[key as keyof typeof NUMERIC_OPERATORS],
  label: key
    .replace(/([A-Z])/g, " $1")
    .toLowerCase()
    .replace(/^\w/, char => char.toUpperCase()),
}));

const booleanOperatorNames = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const styles = makeStyles(theme => ({}));

interface Row {
  conditionSettings: ConditionSettingsNumeric | ConditionSettingsBoolean | ConditionSettingsString;
}

interface Criteria {
  id?: string;
  name: string;
  rows: Row[];
  notes: string;
}

const M_Create_Prescreen_Criteria = gql`
  mutation CreatePrescreenCriteria($input: CreatePrescreenCriteriaInput!) {
    createPrescreenCriteria(input: $input) {
      prescreenCriteriaId
      name
      accountId
      userProfileIdCreatedBy
      jsonCriteria
      active
      notes
      guidelines
    }
  }
`;

const M_Update_Prescreen_Criteria = gql`
  mutation UpdatePrescreenCriteria($input: UpdatePrescreenCriteriaInput!) {
    updatePrescreenCriteria(input: $input) {
      prescreenCriteriaId
      name
      accountId
      jsonCriteria
      notes
      guidelines
      userProfileIdCreatedBy
      active
    }
  }
`;

const formatConditionToSave = (addedCondition: any, factTypes: string[]) => {
  if (
    factTypes.includes(_.get(addedCondition, "conditionSettings.fact")) &&
    !_.get(addedCondition, "conditionSettings.exactMatch").every((item: any) => typeof item === "string")
  ) {
    return addedCondition.conditionSettings.exactMatch.map((item: any) => item.code);
  }
  return addedCondition.conditionSettings.exactMatch;
};
const formatNames = (name: string) => {
  return name
    .replace(/([A-Z])/g, " $1")
    .replace(/And/g, "and")
    .trim();
};

const capitalize = (s: string) => {
  if (s) {
    if (s.length <= 4) return s.toUpperCase();
    return s
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, char => char.toUpperCase());
  } else return s;
};

function getRegionName(list: any, code: string): string | undefined {
  const entries = Object.entries(list) as [string, string][];
  const entry = entries.find(([key, value]) => value === code);
  return entry ? formatNames(entry[0]) : undefined;
}

function getLegalStructureName(list: any, code: string): string | undefined {
  const entries = Object.entries(list) as [string, string][];
  const entry = entries.find(([key, value]) => value === code);
  return entry ? capitalize(entry[0]) : undefined;
}
function getFinancingTypeName(list: any, code: string): string | undefined {
  return FINANCING_TYPE[code as keyof typeof FINANCING_TYPE];
}

const CriteriaForm = ({
  account,
  updatePrescreenCriteriaId,
  selectedPrescreenCriteriaId,
  targetLenderProfileId,
  triggerSetSnackbarOpen,
  initCriteria,
  updateInitCriteria,
  psCriteriaData,
  psCriteriaLoading,
  refetchPsCriteria,
  setHasCriteriaFormUnsavedChanges,
  criteriaFormSelectedCriteriaIndex,
  setCriteriaFormSelectedCriteriaIndex,
}: {
  account: any;
  updatePrescreenCriteriaId: Function;
  selectedPrescreenCriteriaId: string;
  targetLenderProfileId: string;
  triggerSetSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initCriteria: any;
  updateInitCriteria: any;

  psCriteriaData: any;
  psCriteriaLoading: boolean;
  refetchPsCriteria: any;
  setHasCriteriaFormUnsavedChanges: any;
  criteriaFormSelectedCriteriaIndex: number | null;
  setCriteriaFormSelectedCriteriaIndex: any;
}) => {
  const classes = styles();
  const [createPrescreenCriteria] = useMutation(M_Create_Prescreen_Criteria, {
    context: { authRequired: true },
  });
  const [updatePrescreenCriteria] = useMutation(M_Update_Prescreen_Criteria, {
    context: { authRequired: true },
  });

  const [savedCriterias, setSavedCriterias] = useState<Criteria[]>([]);
  const [newCriteriaName, setNewCriteriaName] = useState(_.get(initCriteria, "newCriteriaName", ""));
  const [newCriteriaNotes, setNewCriteriaNotes] = useState(_.get(initCriteria, "newCriteriaNotes", ""));
  const [newCriteriaFinanceProgramGuidelines, setNewCriteriaFinanceProgramGuidelines] = useState(
    _.get(initCriteria, "newCriteriaFinanceProgramGuidelines", "")
  );
  const [toggleUpdate, setToggleUpdate] = useState(true);

  const [newCriteriaRows, setNewCriteriaRows] = useState<Row[]>(_.get(initCriteria, "newCriteriaRows", []));
  const [nameError, setNameError] = useState(false);
  const [availableFacts, setAvailableFacts] = useState<(FactBooleanType | FactNumericType | FactStringType)[]>([
    ...factNamesBoolean,
    ...factNamesNumeric,
    ...factNamesString,
  ]);

  // Workaround: set value in timeout to avoid value overwriting in updateInitCriteria useEffect
  const setHasCriteriaFormUnsavedChangestTimedOut = (value: boolean) => {
    setTimeout(() => {
      setHasCriteriaFormUnsavedChanges(value);
    }, 500);
  };

  const getNewCriteriaRows = (savedCriterias: Criteria[], criteriaFormSelectedCriteriaIndex: number) => {
    const selectedCriteria = savedCriterias[criteriaFormSelectedCriteriaIndex];

    setNewCriteriaName(selectedCriteria.name);
    setNewCriteriaNotes(selectedCriteria.notes);

    //Transform the rows to the format that the form expects
    const transformedCriteriasRows: Criteria["rows"] = selectedCriteria.rows.map((row: any) => {
      //Clone variable to avoid modifying the original object and corrupting the data
      let prev = _.cloneDeep(row);
      if (_.get(prev, "conditionSettings.fact") == "CANADA_REGION") {
        prev.conditionSettings.exactMatch = prev.conditionSettings.exactMatch.map((region: string) => ({
          label: getRegionName(CANADA_REGION_CODES, region),
          code: region,
        }));
      }
      if (_.get(prev, "conditionSettings.fact") == "USA_STATE") {
        prev.conditionSettings.exactMatch = prev.conditionSettings.exactMatch.map((region: string) => ({
          label: getRegionName(US_REGION_CODES, region),
          code: region,
        }));
      }

      if (_.get(prev, "conditionSettings.fact") == "LEGAL_STRUCTURE") {
        prev.conditionSettings.exactMatch = prev.conditionSettings.exactMatch.map((ls: string) => ({
          label: getLegalStructureName(LEGAL_STRUCTURE, ls),
          code: ls,
        }));
      }

      if (_.get(prev, "conditionSettings.fact") == "FINANCING_TYPE") {
        prev.conditionSettings.exactMatch = prev.conditionSettings.exactMatch.map((ft: string) => ({
          label: getFinancingTypeName(FINANCING_TYPE, ft),
          code: ft,
        }));
      }
      if (_.get(prev, "conditionSettings.fact") == "EQUIPMENT_CONDITION") {
        prev.conditionSettings.exactMatch = prev.conditionSettings.exactMatch.map((ec: string) => ({
          label: capitalize(EQUIPMENT_CONDITION[ec as keyof typeof EQUIPMENT_CONDITION]),
          code: ec,
        }));
      }
      return prev;
    });
    return transformedCriteriasRows;
  };

  useEffect(() => {
    updateInitCriteria({
      newCriteriaName,
      newCriteriaNotes,
      newCriteriaFinanceProgramGuidelines,
      newCriteriaRows,
    });
    setHasCriteriaFormUnsavedChanges(true);
  }, [newCriteriaName, newCriteriaNotes, newCriteriaFinanceProgramGuidelines, newCriteriaRows]);

  useEffect(() => {
    console.log({ selectedPrescreenCriteriaId });
  }, [selectedPrescreenCriteriaId]);

  useEffect(() => {
    if (psCriteriaData && psCriteriaData.prescreenCriteria) {
      const criteriasToLoad = [
        ...psCriteriaData.prescreenCriteria.map((ps: any) => ({
          id: ps.prescreenCriteriaId,
          name: ps.name,
          rows: _.get(ps, "jsonCriteria.formRules", []).map(
            (fr: ConditionSettingsNumeric | ConditionSettingsBoolean) => ({
              conditionSettings: fr,
            })
          ),
          notes: _.get(ps, "notes", ""),
          guidelines: _.get(ps, "guidelines", ""),
        })),
      ];

      setSavedCriterias(criteriasToLoad);
      if (selectedPrescreenCriteriaId) {
        const preselectedCriteriaIndex = _.findIndex(criteriasToLoad, { id: selectedPrescreenCriteriaId });
        if (preselectedCriteriaIndex !== -1) {
          setCriteriaFormSelectedCriteriaIndex(preselectedCriteriaIndex);
          if (_.isEmpty(newCriteriaRows)) {
            // initial Tab load
            const newCriteriaRows = getNewCriteriaRows(criteriasToLoad, preselectedCriteriaIndex);
            setNewCriteriaRows(newCriteriaRows);
            setHasCriteriaFormUnsavedChangestTimedOut(false);
          }
        }
      }
    }
  }, [psCriteriaData]);

  useEffect(() => {
    setToggleUpdate(!!(criteriaFormSelectedCriteriaIndex && criteriaFormSelectedCriteriaIndex !== -1));
    if (!_.isEmpty(savedCriterias) && criteriaFormSelectedCriteriaIndex && criteriaFormSelectedCriteriaIndex !== -1) {
      const newCriteriaRows = getNewCriteriaRows(savedCriterias, criteriaFormSelectedCriteriaIndex);
      setNewCriteriaRows(newCriteriaRows);
    } else {
      if (criteriaFormSelectedCriteriaIndex === -1) {
        console.log(`Clear fields ${criteriaFormSelectedCriteriaIndex}`);
        setNewCriteriaName("");
        setNewCriteriaNotes("");
        setNewCriteriaRows([]);
      }
    }
  }, [criteriaFormSelectedCriteriaIndex]);

  const getNextRowElements = (): ConditionSettingsNumeric | ConditionSettingsBoolean | null => {
    const selectedFacts = new Set(newCriteriaRows.map(row => row.conditionSettings.fact));
    const remainingNumericFacts = factNamesNumeric.filter(fact => !selectedFacts.has(fact.value));
    const remainingBooleanFacts = factNamesBoolean.filter(fact => !selectedFacts.has(fact.value));

    if (remainingNumericFacts.length > 0) {
      return { fact: remainingNumericFacts[0].value, minValue: null, maxValue: null };
    } else if (remainingBooleanFacts.length > 0) {
      return { fact: remainingBooleanFacts[0].value, value: false };
    }

    return null;
  };

  const handleSelectCriteria = (event: React.ChangeEvent<{ value: unknown }>) => {
    const index = event.target.value as number;
    if (index === -1) {
      // -1 connects to "Create New"
      console.log(`Create new, index: ${index}`);
      setCriteriaFormSelectedCriteriaIndex(null);
      setNewCriteriaName("");
      setNewCriteriaNotes("");
      setNewCriteriaRows([]);
    } else {
      setCriteriaFormSelectedCriteriaIndex(index);

      updatePrescreenCriteriaId(_.get(savedCriterias, `[${index}].id`, ""));
      setNewCriteriaFinanceProgramGuidelines(_.get(savedCriterias, `[${index}].guidelines`, ""));
    }
  };

  const handleChangeFact = (event: React.ChangeEvent<{ value: unknown }>, rowIndex: number) => {
    const updatedRows = [...newCriteriaRows];
    const isBoolean = Object.keys(LENDER_WATERFALL_FACT_NAMES_BOOLEAN).includes(String(event.target.value));
    const isNumeric = Object.keys(LENDER_WATERFALL_FACT_NAMES_NUMERIC).includes(String(event.target.value));

    if (isBoolean) {
      updatedRows[rowIndex].conditionSettings = {
        fact: event.target.value as LENDER_WATERFALL_FACT_NAMES_BOOLEAN,
        value: false,
      };
    } else if (isNumeric) {
      updatedRows[rowIndex].conditionSettings = {
        fact: event.target.value as LENDER_WATERFALL_FACT_NAMES_NUMERIC,
        minValue: null,
        maxValue: null,
      };
    } else {
      updatedRows[rowIndex].conditionSettings = {
        fact: event.target.value as LENDER_WATERFALL_FACT_NAMES_STRING,
        exactMatch: "",
      };
    }

    setNewCriteriaRows(updatedRows);
  };
  const currentRowIsBooleanFact = (rowIndex: number) => {
    return Object.keys(LENDER_WATERFALL_FACT_NAMES_BOOLEAN).includes(
      String(newCriteriaRows[rowIndex].conditionSettings.fact)
    );
  };

  const currentRowIsNumericFact = (rowIndex: number) => {
    return Object.keys(LENDER_WATERFALL_FACT_NAMES_NUMERIC).includes(
      String(newCriteriaRows[rowIndex].conditionSettings.fact)
    );
  };

  const currentRowIsStringFact = (rowIndex: number) => {
    return Object.keys(LENDER_WATERFALL_FACT_NAMES_STRING).includes(
      String(newCriteriaRows[rowIndex].conditionSettings.fact)
    );
  };

  const handleChangeValue = (event: any, rowIndex: number, fieldName: string) => {
    const updatedRows = [...newCriteriaRows];

    if (fieldName === "requiredForQuote") {
      _.set(updatedRows, `[${rowIndex}].conditionSettings.${fieldName}`, event.target.checked);
      setNewCriteriaRows(updatedRows);
      return;
    }

    // String inputs
    if (_.has(updatedRows, `[${rowIndex}].conditionSettings.exactMatch`)) {
      _.set(updatedRows, `[${rowIndex}].conditionSettings.${fieldName}`, event.target.value);

      setNewCriteriaRows(updatedRows);
      return;
    }

    let value = _.isEmpty(event.target.value) ? null : event.target.value;
    if (isNaN(value)) {
      switch (value) {
        case "true":
          value = true;
          break;
        case "false":
          value = false;
          break;
        default:
          return;
      }
    }
    _.set(updatedRows, `[${rowIndex}].conditionSettings.${fieldName}`, value);

    setNewCriteriaRows(updatedRows);
  };

  const handleSaveCriteriaGroup = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (!newCriteriaName) {
      setNameError(true);
      return;
    }
    const lenderWaterfallConditionsInstance = new LenderWaterfallConditions();

    if (criteriaFormSelectedCriteriaIndex) {
      const updatedCriteria: Criteria = {
        id: savedCriterias[criteriaFormSelectedCriteriaIndex].id,
        name: newCriteriaName,
        rows: newCriteriaRows,
        notes: newCriteriaNotes,
      };

      newCriteriaRows.map((row: any) => {
        let addedCondition = _.cloneDeep(row);

        addedCondition.conditionSettings.exactMatch = formatConditionToSave(addedCondition, [
          "CANADA_REGION",
          "USA_STATE",
          "LEGAL_STRUCTURE",
          "FINANCING_TYPE",
          "EQUIPMENT_CONDITION",
        ]);

        lenderWaterfallConditionsInstance.addCondition(addedCondition.conditionSettings);
      });

      await updatePrescreenCriteria({
        variables: {
          input: {
            accountId: account.id,
            prescreenCriteriaId: updatedCriteria.id,
            name: updatedCriteria.name,
            jsonCriteria: lenderWaterfallConditionsInstance.getConditions(),
            notes: updatedCriteria.notes,
            guidelines: DOMPurify.sanitize(newCriteriaFinanceProgramGuidelines, {
              ALLOWED_TAGS: ["b", "i", "em", "u", "strong", "ol", "ul", "li", "p", "br"],
            }),
          },
        },
      }).then(() => {
        refetchPsCriteria();
        triggerSetSnackbarOpen(true);
        setHasCriteriaFormUnsavedChangestTimedOut(false);
      });

      return;
    }
    setNameError(false);
    const newCriteria: Criteria = {
      name: newCriteriaName,
      rows: newCriteriaRows,
      notes: newCriteriaNotes,
    };

    newCriteriaRows.map(row => {
      lenderWaterfallConditionsInstance.addCondition(row.conditionSettings);
    });

    const result = await createPrescreenCriteria({
      variables: {
        input: {
          accountId: account.id,
          name: newCriteriaName,
          jsonCriteria: lenderWaterfallConditionsInstance.getConditions(),
          lenderProfileIdsToLink: [targetLenderProfileId],
          notes: newCriteriaNotes,
          guidelines: newCriteriaFinanceProgramGuidelines,
        },
      },
    });
    const newPrescreenCriteriaId = _.get(result, "data.createPrescreenCriteria.prescreenCriteriaId", "");

    updatePrescreenCriteriaId(newPrescreenCriteriaId);
    console.log(`New Criteria created: ${newPrescreenCriteriaId}`);
    //Reset form
    setSavedCriterias([...savedCriterias, { ...newCriteria, id: newPrescreenCriteriaId }]);
    await refetchPsCriteria();
    triggerSetSnackbarOpen(true);
    setHasCriteriaFormUnsavedChangestTimedOut(false);
    // const preselectedCriteriaIndex = _.findIndex(savedCriterias, { id: selectedPrescreenCriteriaId });
    // if (preselectedCriteriaIndex !== -1) {
    //   setCriteriaFormSelectedCriteriaIndex(preselectedCriteriaIndex);
    // } else {
    //   //Reset
    //   setCriteriaFormSelectedCriteriaIndex(null);
    //   setNewCriteriaName("");
    //   setNewCriteriaNotes("");
    //   setNewCriteriaRows([]);
    // }
  };

  const renderNumericInputByLenderWaterfallFactType = (fact: string) => {
    switch (fact) {
      case LENDER_WATERFALL_FACT_NAMES_NUMERIC.DEAL_SIZE:
        return CustomCurrencyInput;
      default:
        return undefined;
    }
  };

  const renderStringInputByLenderWaterfallFactType = (rowIndex: number, row: any) => {
    switch (row.conditionSettings.fact) {
      case LENDER_WATERFALL_FACT_NAMES_STRING.EQUIPMENT_CONDITION:
        const equipmentConditionOptions = Object.keys(EQUIPMENT_CONDITION).map(key => ({
          code: key,
          label: EQUIPMENT_CONDITION[key as keyof typeof EQUIPMENT_CONDITION],
        }));
        return (
          <Autocomplete
            size="small"
            multiple={true}
            defaultValue={_.get(row, "conditionSettings.exactMatch") || []}
            onChange={(event, equipmentConditionOption) => {
              return handleChangeValue(
                {
                  target: {
                    value: equipmentConditionOption.map(equipCond => equipCond.code),
                  },
                },
                rowIndex,
                "exactMatch"
              );
            }}
            options={equipmentConditionOptions}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={option => `${capitalize(option.label)}`}
            renderInput={params => <TextField {...params} label="Select the Equipment Condition" variant="outlined" />}
          />
        );
      case LENDER_WATERFALL_FACT_NAMES_STRING.FINANCING_TYPE:
        const listToRenderFinancingType = Object.keys(FINANCING_TYPE).map(key => ({
          code: key,
          label: FINANCING_TYPE[key as keyof typeof FINANCING_TYPE],
        }));
        return (
          <Autocomplete
            size="small"
            multiple={true}
            defaultValue={_.get(row, "conditionSettings.exactMatch") || []}
            onChange={(event, financingTypes) => {
              return handleChangeValue(
                {
                  target: {
                    value: financingTypes.map(financingType => financingType.code),
                  },
                },
                rowIndex,
                "exactMatch"
              );
            }}
            options={listToRenderFinancingType}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={option => `${capitalize(option.label)}`}
            renderInput={params => <TextField {...params} label="Select a Financing Type" variant="outlined" />}
          />
        );
      case LENDER_WATERFALL_FACT_NAMES_STRING.LEGAL_STRUCTURE:
        const listToRenderLegalStructure = Object.keys(LEGAL_STRUCTURE).map(key => ({
          label: key,
          code: LEGAL_STRUCTURE[key as keyof typeof LEGAL_STRUCTURE],
        }));
        return (
          <Autocomplete
            size="small"
            multiple={true}
            defaultValue={_.get(row, "conditionSettings.exactMatch") || []}
            onChange={(event, legalStructures) => {
              return handleChangeValue(
                {
                  target: {
                    value: legalStructures.map(legalStructure => legalStructure.code),
                  },
                },
                rowIndex,
                "exactMatch"
              );
            }}
            options={listToRenderLegalStructure}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={option => `${capitalize(option.label)}`}
            renderInput={params => <TextField {...params} label="Select a Legal Structure" variant="outlined" />}
          />
        );
      case LENDER_WATERFALL_FACT_NAMES_STRING.CANADA_REGION:
        const canadaSelectedValues = _.cloneDeep(_.get(row, "conditionSettings.exactMatch")) || [];
        const listToRenderCanadaStates = _.cloneDeep(
          Object.keys(CANADA_REGION_CODES).map(key => ({
            label: formatNames(key),
            code: CANADA_REGION_CODES[key as keyof typeof CANADA_REGION_CODES],
          }))
        );

        return (
          <Autocomplete
            id="canada-state-autocomplete"
            key={"canada-state-autocomplete"}
            size="small"
            multiple={true}
            options={listToRenderCanadaStates}
            getOptionSelected={(option, value) => option.code === value.code}
            onChange={(event, states) => {
              return handleChangeValue(
                {
                  target: {
                    value: states.map(state => state.code),
                  },
                },
                rowIndex,
                "exactMatch"
              );
            }}
            getOptionLabel={option => `${option.label} (${option.code})`}
            defaultValue={canadaSelectedValues}
            renderInput={params => <TextField {...params} label="Select a Region" variant="outlined" />}
          />
        );
      case LENDER_WATERFALL_FACT_NAMES_STRING.USA_STATE:
        const contained = _.cloneDeep(_.get(row, "conditionSettings.exactMatch")) || [];
        const listToRenderStates = Object.keys(US_REGION_CODES).map(key => ({
          label: formatNames(key),
          code: US_REGION_CODES[key as keyof typeof US_REGION_CODES],
        }));

        return (
          <Autocomplete
            id="usa-state-autocomplete"
            key={"usa-state-autocomplete"}
            size="small"
            multiple={true}
            options={listToRenderStates}
            getOptionSelected={(option, value) => option.code === value.code}
            onChange={(event, states) => {
              return handleChangeValue(
                {
                  target: {
                    value: states.map(state => state.code),
                  },
                },
                rowIndex,
                "exactMatch"
              );
            }}
            getOptionLabel={option => `${option.label} (${option.code})`}
            defaultValue={contained}
            renderInput={params => <TextField {...params} label="Select a State" variant="outlined" />}
          />
        );
      default:
        return (
          <ChipInput
            id={`exact-match-${rowIndex}`}
            onChange={(id: string, value: string[]) => handleChangeValue({ target: { value } }, rowIndex, "exactMatch")}
            initialValues={getStringFactInitialValues(row)}
          />
        );
    }
  };

  const handleAddRow = () => {
    const baseCondition = getNextRowElements();
    if (!baseCondition) {
      alert("No more available facts to add.");
      return;
    }
    setNewCriteriaRows([
      ...newCriteriaRows,
      {
        conditionSettings: baseCondition,
      },
    ]);
  };

  const isReadOnly = criteriaFormSelectedCriteriaIndex !== null;

  const getAvailableFactsForRow = (rowIndex: number) => {
    const selectedFacts = new Set(newCriteriaRows.map(row => row.conditionSettings.fact));
    const currentFact = newCriteriaRows[rowIndex].conditionSettings.fact;
    selectedFacts.delete(currentFact);

    return availableFacts.filter(fact => !selectedFacts.has(fact.value));
  };

  const getStringFactInitialValues = (row: any): string[] => {
    const value = _.get(row, `conditionSettings.exactMatch`, [] as string[]);
    if (_.isArray(value)) {
      return value;
    }
    if (_.isString(value) && value !== "") {
      return [value];
    }
    return [];
  };

  return (
    <Grid container spacing={2}>
      {psCriteriaLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      <Grid item xs={12} hidden={psCriteriaLoading}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel shrink={true} id="criteria-label">
                Select Group
              </InputLabel>
              <Select
                labelId="criteria-label"
                id="criteria"
                value={criteriaFormSelectedCriteriaIndex !== null ? criteriaFormSelectedCriteriaIndex : -1}
                onChange={handleSelectCriteria}
                label="Available"
                style={{ height: 40 }}
              >
                <MenuItem value={-1}>Create New</MenuItem>
                {savedCriterias.map((criteria, index) => (
                  <MenuItem key={`criteria_${index}`} value={index}>
                    <ListItemText primary={criteria.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} hidden={psCriteriaLoading}>
        <Divider />
      </Grid>
      <Grid item xs={12} hidden={psCriteriaLoading}>
        <Grid container spacing={4}>
          {/* <Grid item xs={12}>
            <h5 style={{ textDecoration: "underline" }}>{isReadOnly ? "Saved conditions" : "New Criteria Group"}</h5>
          </Grid> */}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {!isReadOnly && (
                <Grid item xs={12}>
                  <TextField
                    label="Group Name"
                    size="small"
                    variant="outlined"
                    value={newCriteriaName}
                    onChange={e => setNewCriteriaName(e.target.value)}
                    style={{ marginBottom: "16px", width: "33%" }}
                    InputProps={{ readOnly: isReadOnly }}
                    required={!isReadOnly}
                    error={nameError && !newCriteriaName}
                    helperText={nameError && !newCriteriaName ? "Group Name is required" : ""}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {newCriteriaRows.map((row, rowIndex) => (
              <Grid container key={`criteria_fp_row_${rowIndex}`} alignItems="center">
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <span style={{ borderRadius: "50%", margin: "0 5px 0 0 ", padding: 0, float: "left" }}>
                        <IconButton
                          aria-label="close"
                          size="small"
                          onClick={() => {
                            setNewCriteriaRows(prevRows => prevRows.filter((_, i) => i !== rowIndex));
                          }}
                        >
                          <RemoveCircleIcon fontSize="small" style={{ color: "red", fontSize: "20px" }} />
                        </IconButton>
                      </span>
                      <span style={{ fontSize: "14px", fontWeight: "bolder", lineHeight: "12px" }}>
                        Condition #{rowIndex + 1}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id={`new-fact-label-${rowIndex}`}>Rule</InputLabel>
                      <Select
                        labelId={`new-fact-label-${rowIndex}`}
                        id={`new-fact-${rowIndex}`}
                        value={row.conditionSettings.fact}
                        onChange={e => handleChangeFact(e, rowIndex)}
                        label="Rule"
                      >
                        {getAvailableFactsForRow(rowIndex).map((fact, index) => (
                          <MenuItem key={index} value={fact.value}>
                            {fact.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {currentRowIsBooleanFact(rowIndex) && (
                    <>
                      <Grid item xs={4}>
                        <FormControl fullWidth variant="standard" size="small">
                          <InputLabel id={`new-operator-label-${rowIndex}`}></InputLabel>
                          <Select
                            labelId={`new-operator-label-${rowIndex}`}
                            id={`new-operator-${rowIndex}`}
                            value={_.get(row, "conditionSettings.value")}
                            onChange={e => handleChangeValue(e, rowIndex, "value")}
                            label="Option"
                          >
                            {booleanOperatorNames.map(element => (
                              <MenuItem key={element.label} value={String(element.value)}>
                                {element.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  {currentRowIsNumericFact(rowIndex) && (
                    <>
                      <Grid item xs={2}>
                        <InputLabel id={`min-value-label-${rowIndex}`}>Min Value</InputLabel>
                        <TextField
                          id={`min-value-${rowIndex}`}
                          variant="standard"
                          type="text"
                          value={_.get(row, `conditionSettings.minValue`)}
                          onChange={e => handleChangeValue(e, rowIndex, "minValue")}
                          fullWidth
                          InputProps={{
                            inputComponent: renderNumericInputByLenderWaterfallFactType(row.conditionSettings.fact),
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <InputLabel id={`max-value-label-${rowIndex}`}>Max Value</InputLabel>
                        <TextField
                          id={`max-value-${rowIndex}`}
                          variant="standard"
                          type="text"
                          value={_.get(row, `conditionSettings.maxValue`)}
                          onChange={e => handleChangeValue(e, rowIndex, "maxValue")}
                          fullWidth
                          InputProps={{
                            inputComponent: renderNumericInputByLenderWaterfallFactType(row.conditionSettings.fact),
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {currentRowIsStringFact(rowIndex) && (
                    <>
                      <Grid item xs={4}>
                        {renderStringInputByLenderWaterfallFactType(rowIndex, row)}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={1}>
                    <InputLabel id={`required-to-quote-label-${rowIndex}`}>Required to Quote?</InputLabel>
                    <Switch
                      checked={_.get(row, "conditionSettings.requiredForQuote", false)}
                      onChange={e => handleChangeValue(e, rowIndex, "requiredForQuote")}
                      color="primary"
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}

            {getNextRowElements() && (
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs={2}>
                  <MUIButton
                    style={{ marginTop: "20px" }}
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={handleAddRow}
                    startIcon={<AddIcon />}
                  >
                    Criteria row
                  </MUIButton>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Internal Notes"
              fullWidth
              placeholder="(optional)"
              size="small"
              variant="outlined"
              multiline
              maxRows={4}
              value={newCriteriaNotes}
              onChange={e => setNewCriteriaNotes(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel id={"guidelines"}>Program Guidelines</InputLabel>
            <ReactQuill
              theme="snow"
              value={newCriteriaFinanceProgramGuidelines}
              onChange={value => setNewCriteriaFinanceProgramGuidelines(value)}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline"], // ,'strike', 'blockquote'
                  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                  // ['link', 'image'],
                  // ['clean']
                ],
              }}
            />
          </Grid>

          <Grid item xs={12} justifyContent="center">
            <MUIButton
              color="primary"
              disabled={_.isEmpty(newCriteriaRows)}
              variant="contained"
              onClick={handleSaveCriteriaGroup}
              endIcon={<SaveIcon />}
            >
              {toggleUpdate ? "Update" : "Create"}
            </MUIButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CriteriaForm;
