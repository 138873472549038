import React, { PropsWithChildren } from "react";
import { ThemeProvider, createTheme } from "@material-ui/core";

export const theme = createTheme({
  props: {
    MuiButton: {
      color: "primary",
      variant: "outlined",
    },
  },
  overrides: {
    MuiInput: {
      root: {
        "& input[type=number]": {
          "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
      },
    },
    MuiInputLabel: {
      asterisk: {
        color: "red",
      },
    },
  },
  palette: {
    primary: {
      main: "#0c84fc",
      light: "#54a8fc",
      dark: "#085cb0",
      contrastText: "#fff",
    },
  },
});

const ThemeWrapper = ({ children }: PropsWithChildren) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
