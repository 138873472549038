import React from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/react-hooks";
import { documentsByKey } from "../../../pages/Prequal/constants";
import { BaseModalProps } from "../../../global";
import { UPDATE_DOC } from "../../Documents_V2/queries";

interface Props extends BaseModalProps {
  updateDocument: any;
  setUpdateDocument: any;
  refetchDocuments: () => Promise<any>;
}

export const UpdateDocumentModal = ({
  isOpen,
  onClose,
  updateDocument,
  setUpdateDocument,
  refetchDocuments,
}: Props) => {
  const classes = useStyles();

  const [updateDoc, { loading: isUpdating }] = useMutation(UPDATE_DOC, {
    context: { authRequired: true },
  });

  const handleUpdateDoc = async () => {
    try {
      await updateDoc({
        variables: {
          id: updateDocument.key,
          input: {
            docType: updateDocument.docType,
            docDescription: updateDocument.description,
          },
        },
      });
      await refetchDocuments();
    } catch (err) {
      console.log(err);
    } finally {
      onClose();
      setUpdateDocument(null);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby={updateDocument?.docName || ""} aria-describedby="Summary">
      <div className={classes.documentModal}>
        <h3>{updateDocument?.name || ""}</h3>
        <div style={{ width: "100%", justifyContent: "flex-start", padding: 16 }}>
          <Box component="form" style={{ marginBottom: 16 }}>
            <TextField
              rows={4}
              multiline
              fullWidth
              variant="outlined"
              label="Description"
              value={updateDocument?.description || ""}
              onChange={e => {
                e.persist();
                setUpdateDocument((prev: any) => ({ ...prev, description: e.target.value }));
              }}
            />
          </Box>
          <FormControl fullWidth>
            <InputLabel id="type-select-label">Type</InputLabel>

            <Select
              label="Type"
              id="type-select"
              labelId="type-select-label"
              value={updateDocument?.docType || ""}
              onChange={e => setUpdateDocument((prev: any) => ({ ...prev, docType: e.target.value }))}
            >
              {Object.values(documentsByKey).map(({ value, text }) => (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Button onClick={() => handleUpdateDoc()} style={{ marginBottom: 32 }} disabled={isUpdating}>
          {isUpdating ? "Updating..." : "Update"}
        </Button>
      </div>
    </Modal>
  );
};

const useStyles = makeStyles(theme => ({
  documentModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 540,
    borderRadius: 8,
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 768px)": {
      width: "96vw",
      minWidth: "96vw",
    },
  },
}));
