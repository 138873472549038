import { MarkupTyped } from "@trnsact/trnsact-shared-types/src/generated";
import { ProposalProduct, FlatFee } from "@trnsact/trnsact-shared-types/dist/generated";
import { CalculateMultiplePaymentsPaymentOptionsResponse } from "@trnsact/trnsact-shared-types";

export enum SendVia {
  Email = "Email",
  Text = "Text",
  TextAndEmail = "TextAndEmail",
}

export enum FinanceQuoteMode {
  Finance = "Finance",
  Menu = "Menu",
}

export enum RateTypes {
  InterestRate = "INTEREST_RATE",
  RateFactor = "RATE_FACTOR",
}

export enum Structures {
  None = "none",
  Markup = "financeProfit",
  Subsidy = "subsidy",
}

export enum MarkupMethods {
  Markup = "markup",
  CustomerRate = "customerRate",
}

export enum ProgramTypes {
  Loan = "loan",
  Lease = "lease",
  EFA = "EFA",
}

export enum PaymentCalculationMethods {
  Advance = "BEGINNING_OF_PERIOD",
  Arrears = "END_OF_PERIOD",
}

export enum EquipmentActionsMode {
  Create = "create",
  Update = "update",
}

interface CustomerForm {
  selectedCustomer: string;
  name: string;
  title: string;
  email: string;
  lastName: string;
  firstName: string;
  state: string;
  phone: string;
}

export interface MenuCards {
  paymentOptions: CalculateMultiplePaymentsPaymentOptionsResponse[];
  isCalculating: boolean;
}

interface SaleDetailsForm {
  fees: number;
  salesTax: number;
  downPayment: number;
  invoiceTotal: number;
  financeAmount: number;
  equipmentTotal: number;
}

interface EquipmentForm {
  selectedEquipment: string;
  serialNumberVin: string;
  year: string;
  make: string;
  model: string;
  mileage: string;
  stockId: string;
  quantity: string;
  newUsed: string;
  description: string;
  wholesaleCost: string;
  totalRetailPrice: string;
  totalWholesaleCost: string;
}

interface SpecifyRates {
  term: string;
  rate: string;
}

interface FinanceQuoteForm {
  mode: FinanceQuoteMode;
  commonRate: string;
  amount: string;
  terms: string[];
  program: string;
  residual: string;
  rateType: string;
  structure: string;
  downPayment: string;
  programType: string;
  programName: string;
  dealerMarkup: string;
  specifyRate: boolean;
  advancePayment: string;
  specifyRatePerTerm: string;

  markupRate: string;
  markupMethod: string;
  paymentCalculationMethod: string;

  subsidyType: string;

  specifyRates: SpecifyRates[];
  fee: FlatFee[];

  menu: string;
}

interface SendViaForm {
  type: SendVia;
}

interface ProposalSettings {
  productPrice: boolean;
  productDetails: boolean;
}

interface FinanceProgramDetails {
  menuOptionName: string;
  delta: any;
}

export interface FinanceProgramFormValues {
  customer: CustomerForm;
  saleDetails: SaleDetailsForm;
  equipment: EquipmentForm;
  financeQuote: FinanceQuoteForm;
  sendVia: SendViaForm;
  displaySettings: ProposalSettings;
  financeProgramDetails: Record<string, FinanceProgramDetails>;
}

export interface FactToCheck {
  rule: any;
  options?: string[];
  type: "boolean" | "numeric" | "string";
  factKey:
    | "EQUIPMENT_CONDITION"
    | "EQUIPMENT_TYPE"
    | "EQUIPMENT_YEAR"
    | "DEAL_SIZE"
    | "COVERAGE_TERM"
    | "ODOMETER_MILEAGE"
    | "AFTERMARKET_PRODUCT_SOURCE"
    | "EQUIPMENT_MAKE"
    | string;
}

export interface CommonMenuPriceValues {
  cost: number;
  isTotal?: boolean;
  retailCost: number;
  markup: MarkupTyped | null | undefined;
}

export interface SubmitOptions {
  isDraft: boolean;
}

export enum DnDTypes {
  ProposalProduct = "proposalProduct",
}

export enum AftermarketProductTypes {
  Simple = "Simple",
  Gaig = "GAIG",
  Ntp = "NTP",
}

export interface ProposalProductCardProps {
  menuName?: string;
  product: ProposalProduct;
  type?: "simple" | "forEdit";
  mode: ProposalProductCardModes;
  proposalProductConfiguration?: any;
  updateProductConfiguration?: (productId: string, menuName: string, field: string, value: any) => void;
}

export interface FormSectionProps {
  sectionName: FormSections;
  onUpdateCollapse?: (section: FormSections, nextValue: boolean) => void;
}

export enum ProposalProductCardModes {
  Desking = "desking",
  Constructor = "constructor",
}

export enum FormSections {
  Customer = "customer",
  SaleDetails = "saleDetails",
  Equipment = "equipment",
  Structure = "structure",
  MenuBuilder = "menuBuilder",
  SendVia = "sendVia",
}

export enum MarkupType {
  Flat = "FLAT",
  Percentage = "PERCENTAGE",
}

export interface UserActionsEvents {
  onEquipmentCreated: (equipmentId: string) => Promise<void>;
  onEquipmentUpdated: (equipmentId: string) => Promise<void>;
  onEquipmentRemoved: (equipmentId: string) => Promise<void>;
}

export enum ShowTypes {
  All = "ALL",
  Warranty = "WARRANTY",
  Insurance = "INSURANCE",
  GAP = "GAP",
  Roadside = "ROADSIDE",
}

export interface ProductsFilters {
  search: string;
  vendors: string[];
  showOnly: ShowTypes;
}

export interface SimpleProductsDynamicFieldsProps {
  setFacts: any;
  fact: FactToCheck;
  disabled: boolean;
  facts: Record<string, any>;
  productConfigurationRulesEngine: any;
}

export enum AftermarketCatalogDetailsKeys {
  ProductSummary = "productSummary",
  ParagraphDescription = "paragraphDescription",
  CoverageDetails = "coverageDetails",
  EligibilityAndInclusions = "eligibilityAndInclusions",
  LimitationsOfLiability = "limitationsOfLiability",
  LegalDisclaimer = "legalDisclaimer",
}

export interface CatalogDetailsToRenderAsLinkConfig {
  key: AftermarketCatalogDetailsKeys;
  label: string;
}
