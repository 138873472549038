import React from "react";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SubmitOptions } from "../../../types";

interface Props {
  isProposalLoading: boolean;
  onSubmitForm: (options: SubmitOptions) => Promise<void>;
}

export const PanelFormActions = ({ onSubmitForm, isProposalLoading }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.actions}>
      <Button variant="contained" disabled={isProposalLoading} onClick={() => onSubmitForm({ isDraft: false })}>
        Send
      </Button>

      <Button disabled={isProposalLoading} onClick={() => onSubmitForm({ isDraft: true })}>
        Save as draft
      </Button>
    </Box>
  );
};

const useStyles = makeStyles({
  actions: {
    gap: "8px",
    display: "flex",
  },
});
