import {
  AftermarketCatalogDetailsKeys,
  AftermarketProductTypes,
  CatalogDetailsToRenderAsLinkConfig,
  FactToCheck,
  FinanceProgramFormValues,
  MarkupMethods,
  MarkupType,
  PaymentCalculationMethods,
  ProgramTypes,
  RateTypes,
  SendVia,
  ShowTypes,
  Structures,
} from "../types";
import _ from "lodash";
import { ValueOf } from "../../../global";
import { formatNumberWithDelimiter } from "../../../utils";
import NTP_LOGO from "../../../assets/img/aftermarket-vendor-logos/ntp-logo.png";
import GAIG_LOGO from "../../../assets/img/aftermarket-vendor-logos/gaig-logo.png";

const sendViaOptions = [
  { label: "Email", value: SendVia.Email },
  { label: "Text", value: SendVia.Text },
  { label: "Text and email", value: SendVia.TextAndEmail },
];

const rateTypeOptions = [
  { label: "Interest Rate", value: RateTypes.InterestRate },
  { label: "Rate Factor", value: RateTypes.RateFactor },
];

const structureOptions = [
  { label: "None", value: Structures.None },
  { label: "Markup", value: Structures.Markup },
  { label: "Subsidy", value: Structures.Subsidy },
];

const markupOptions = [
  { label: "Markup %", value: MarkupMethods.Markup },
  { label: "Customer Rate", value: MarkupMethods.CustomerRate },
];

const programTypeOptions = [
  { label: "Loan", value: ProgramTypes.Loan },
  { label: "Lease", value: ProgramTypes.Lease },
  { label: "EFA", value: ProgramTypes.EFA },
];

const paymentCalculationMethodOptions = [
  { label: "Advance", value: PaymentCalculationMethods.Advance },
  { label: "Arrears", value: PaymentCalculationMethods.Arrears },
];

const termsOptions = [
  { label: "12", value: "12" },
  { label: "24", value: "24" },
  { label: "36", value: "36" },
  { label: "48", value: "48" },
  { label: "60", value: "60" },
  { label: "72", value: "72" },
  { label: "84", value: "84" },
  { label: "96", value: "96" },
  { label: "108", value: "108" },
  { label: "120", value: "120" },
];

const markupProposalOptions = [
  { label: "$", value: MarkupType.Flat },
  { label: "%", value: MarkupType.Percentage },
];

const equipmentConditions = [
  { label: "New", value: "new" },
  { label: "Used", value: "used" },
  { label: "Demo", value: "demo" },
  { label: "Reconditioned", value: "reconditioned" },
];

const proposalProductsFiltersOptions = [
  { label: "All", value: ShowTypes.All },
  { label: "Warranty", value: ShowTypes.Warranty },
  { label: "Insurance", value: ShowTypes.Insurance },
  { label: "GAP", value: ShowTypes.GAP },
  { label: "Roadside", value: ShowTypes.Roadside },
];

const equipmentYears = _.range(1990, new Date().getFullYear() + 3).map(value => ({ label: `${value}`, value }));

const equipmentDependentFields: (keyof ValueOf<Pick<FinanceProgramFormValues, "equipment">, "equipment">)[] = [
  "year",
  "make",
  "model",
  "stockId",
  "newUsed",
  "quantity",
  "description",
  "wholesaleCost",
  "serialNumberVin",
  "totalRetailPrice",
  "totalWholesaleCost",
];

const factKeyMapToEquipment: Record<FactToCheck["factKey"], keyof FinanceProgramFormValues["equipment"]> = {
  EQUIPMENT_YEAR: "year",
  VIN: "serialNumberVin",
  ODOMETER_MILEAGE: "mileage",
  EQUIPMENT_CONDITION: "newUsed",
};

const factsKeyMapOfFormatters: Record<FactToCheck["factKey"], (value: unknown) => string> = {
  ODOMETER_MILEAGE: value => formatNumberWithDelimiter(value, true),
};

const vendorsLogoByType: Partial<Record<AftermarketProductTypes, string>> = {
  [AftermarketProductTypes.Ntp]: NTP_LOGO,
  [AftermarketProductTypes.Gaig]: GAIG_LOGO,
};

const catalogDetailsKeysToRenderAsSection = [
  AftermarketCatalogDetailsKeys.ProductSummary,
  AftermarketCatalogDetailsKeys.ParagraphDescription,
  AftermarketCatalogDetailsKeys.CoverageDetails,
];

const catalogDetailsToRenderAsLinkConfig: CatalogDetailsToRenderAsLinkConfig[] = [
  {
    key: AftermarketCatalogDetailsKeys.EligibilityAndInclusions,
    label: "Eligibility Requirements",
  },
  {
    key: AftermarketCatalogDetailsKeys.LimitationsOfLiability,
    label: "Limitations of Liability",
  },
  {
    key: AftermarketCatalogDetailsKeys.LegalDisclaimer,
    label: "Legal Disclaimer",
  },
];

export {
  termsOptions,
  markupOptions,
  sendViaOptions,
  equipmentYears,
  rateTypeOptions,
  structureOptions,
  programTypeOptions,
  equipmentConditions,
  markupProposalOptions,
  equipmentDependentFields,
  proposalProductsFiltersOptions,
  vendorsLogoByType,
  paymentCalculationMethodOptions,
  factKeyMapToEquipment,
  factsKeyMapOfFormatters,
  catalogDetailsKeysToRenderAsSection,
  catalogDetailsToRenderAsLinkConfig,
};
