import React from "react";
import { FormInput } from "../FormInput";
import { Controller, InputValidationRules, RegisterOptions } from "react-hook-form";
import { TextFieldProps, Tooltip } from "@material-ui/core";
import { Control } from "react-hook-form/dist/types/form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { UseControllerProps } from "react-hook-form/dist/types/controller";

interface Props<FormValues extends FieldValues = FieldValues> {
  label?: string;
  tooltip?: string;
  rules?: Omit<RegisterOptions<FormValues>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">;
  control: Control<FormValues>;
  formatter?: (value: string) => string;
  name: UseControllerProps<FormValues>["name"];
  inputProps?: Omit<TextFieldProps, "onChange" | "label">;
}

export const InputField = <FormValues extends FieldValues = FieldValues>({
  name,
  label,
  rules,
  control,
  tooltip,
  formatter,
  inputProps = {},
}: Props<FormValues>) => {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field, fieldState }) => (
        <Tooltip title={tooltip || ""} arrow disableHoverListener={!tooltip}>
          <FormInput
            label={label}
            formatter={formatter}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : ""}
            {...inputProps}
            {...field}
          />
        </Tooltip>
      )}
    />
  );
};
