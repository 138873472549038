import React from "react";
import { useQuery } from "react-apollo";
import AddIcon from "@material-ui/icons/Add";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import { ProposalProduct } from "@trnsact/trnsact-shared-types";
import { useModal } from "../../../../../hooks/useModal";
import { FinanceProgramFormValues } from "../../../types";
import { FormInputSelect } from "../../../../../components/form";
import { GET_PROPOSAL_MENUS_SELECT_OPTIONS } from "../../../api";
import { deskingActions, deskingSelectors } from "../../../model";
import { ModalsKeys } from "../../../../../components/shared/Modals";
import { accountSelectors } from "../../../../../redux/accountReducer";
import { MenuSelectsResponse, MenuSelectsVariables } from "../../../api/types";

export const MenuSelector = () => {
  const classes = useStyles();

  const { handleOpen: onOpenProposalProductsDialog } = useModal(ModalsKeys.DeskingProposalProductsDialog);

  const { control, setValue } = useFormContext<FinanceProgramFormValues>();

  const dispatch = useDispatch();

  const accountId = useSelector(accountSelectors.accountId);
  const products = useSelector(deskingSelectors.proposalsProducts) as ProposalProduct[];
  const selectedProducts = useSelector(deskingSelectors.selectedProposalsProductsArray) as ProposalProduct[];

  const { data, loading: isOptionsLoading } = useQuery<MenuSelectsResponse, MenuSelectsVariables>(
    GET_PROPOSAL_MENUS_SELECT_OPTIONS,
    {
      skip: !accountId,
      variables: { accountId },
    }
  );

  const handleChangeMenu = (nextValue: string) => {
    dispatch(deskingActions.resetMenuBuilder());
    setValue("financeQuote.menu", nextValue);
  };

  return (
    <Box className={classes.container}>
      <Controller
        control={control}
        name="financeQuote.menu"
        render={({ field }) => (
          <FormInputSelect
            variant="standard"
            label="Select Menu Template"
            isLoading={isOptionsLoading}
            options={data?.proposalMenus ?? []}
            disabled={!data?.proposalMenus?.length}
            {...field}
            onChange={handleChangeMenu}
          />
        )}
      />

      <Button
        startIcon={<AddIcon />}
        onClick={() => {
          onOpenProposalProductsDialog({ products });
        }}
      >
        {!!selectedProducts?.length ? "Add more" : "Add product"}
      </Button>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "6px",
    display: "flex",
    alignItems: "center",

    "& > button.MuiButton-root": {
      minWidth: "200px",
    },
  },
});
