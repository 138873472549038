import { portalConfigurationTypes } from "./pages/Prequal/constants";

export const SESSION_TIMEOUT_COOKIE_NAME = "userSession";

export const initialPortalConfigTypes = [
  portalConfigurationTypes.scorecard,
  portalConfigurationTypes.leads,
  portalConfigurationTypes.opportunities,
  portalConfigurationTypes.userManagement,
  portalConfigurationTypes.securityManagement,
  portalConfigurationTypes.healthCheck,
  portalConfigurationTypes.creditReports,
  portalConfigurationTypes.documents,
  portalConfigurationTypes.portalSetup,
  portalConfigurationTypes.prescreen,
  portalConfigurationTypes.voChecklistTemplate,
  portalConfigurationTypes.voManagement,
  portalConfigurationTypes.aftermarket,
];

export const CADMPortalConfigTypes = [portalConfigurationTypes.CADMCommercial, portalConfigurationTypes.CADMIndividual];

export const DateFormats = {
  Full: "dddd, MMMM D, YYYY", // Full date (e.g., "Monday, November 12, 2024")
  Long: "MMMM D, YYYY", // Long date (e.g., "November 12, 2024")
  Medium: "MMM D, YYYY", // Medium date (e.g., "Nov 12, 2024")
  Short: "MM/DD/YYYY", // Short date (e.g., "11/12/2024")
  TimeWithFullDate: "dddd, MMMM D, YYYY [at] h:mm A", // Full date with time (e.g., "Monday, November 12, 2024 at 9:30 AM")
  TimeWithShortDate: "MM/DD/YYYY h:mm A", // Short date with time (e.g., "11/12/2024 9:30 AM")
  YearMonth: "YYYY-MM", // Year and month (e.g., "2024-11")
  MonthDay: "MMMM D", // Month and day (e.g., "November 12")
  MonthDayYear: "MM/DD/YYYY", // Month, day, and year in short format (e.g., "11/12/2024")
};
