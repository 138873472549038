import React from "react";
import { Switch, SwitchProps, FormControlLabel } from "@material-ui/core";

interface Props extends SwitchProps {
  label?: React.ReactNode;
}

export const FormSwitch = ({ label, ...switchProps }: Props) => {
  return (
    <FormControlLabel
      style={{ whiteSpace: "nowrap" }}
      control={<Switch color="primary" {...switchProps} />}
      label={label}
    />
  );
};
