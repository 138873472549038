import React from "react";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Control } from "react-hook-form/dist/types/form";
import { TextField, TextFieldProps } from "@material-ui/core";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { UseControllerProps } from "react-hook-form/dist/types/controller";

interface Props<FormValues extends FieldValues = FieldValues> {
  label: string;
  control: Control<FormValues>;
  textFieldProps?: TextFieldProps;
  name: UseControllerProps<FormValues>["name"];
}

export const InterestInputField = <FormValues extends FieldValues = FieldValues>({
  name,
  label,
  control,
  textFieldProps = {},
}: Props<FormValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, onChange, ...field }, fieldState }) => (
        <NumberFormat
          suffix="%"
          label={label}
          allowNegative
          decimalScale={2}
          getInputRef={ref}
          thousandSeparator
          fixedDecimalScale
          variant="standard"
          customInput={TextField}
          {...field}
          {...(textFieldProps as any)}
          error={!!fieldState.error}
          helperText={fieldState.error ? fieldState.error.message : ""}
          value={parseFloat(value)}
          onValueChange={({ value }) => {
            onChange(parseFloat(value));
          }}
        />
      )}
    />
  );
};
