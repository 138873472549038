import React from "react";
import { blue } from "@material-ui/core/colors";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ProposalMenuOption, ProposalProduct } from "@trnsact/trnsact-shared-types";
import { MenuOptionProductDescriptions } from "./MenuOptionProductDescriptions";

interface Props {
  menuOption: ProposalMenuOption;
}

export const MenuOptionProductsDescriptions = ({ menuOption }: Props) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.header}>
        <Typography component="span" variant="subtitle2">
          Product Description(s)
        </Typography>
      </Box>
      {(menuOption.products as ProposalProduct[]).map(product => (
        <MenuOptionProductDescriptions key={product.proposalProductId} menuOption={menuOption} product={product} />
      ))}
    </Box>
  );
};

const useStyles = makeStyles({
  header: {
    padding: "8px",
    display: "flex",
    borderRadius: "2px",
    alignItems: "center",
    backgroundColor: blue["50"],
  },
});
