import React from "react";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { TextField, TextFieldProps } from "@material-ui/core";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import { SelectOption } from "../../../global";

interface Props<
  Option extends SelectOption = SelectOption,
  Multiple extends boolean = false,
  FormValues extends FieldValues = FieldValues
> extends Omit<AutocompleteProps<Option, Multiple, false, false>, "renderInput"> {
  control: Control<FormValues>;
  textFieldProps?: Partial<TextFieldProps>;
  name: UseControllerProps<FormValues>["name"];
}

export const AutocompleteField = <
  Option extends SelectOption = SelectOption,
  Multiple extends boolean = false,
  FormValues extends FieldValues = FieldValues
>({
  name,
  control,
  options,
  textFieldProps = {},
  ...other
}: Props<Option, Multiple, FormValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, ...field }, fieldState }) => (
        <Autocomplete<Option, Multiple, false, false>
          fullWidth
          value={value}
          options={options}
          getOptionLabel={option => option.label}
          onChange={(_, value) => onChange(value)}
          renderInput={params => (
            <TextField
              {...params}
              error={!!fieldState.error}
              helperText={fieldState.error ? fieldState.error.message : ""}
              {...textFieldProps}
            />
          )}
          {...field}
          {...other}
        />
      )}
    />
  );
};
