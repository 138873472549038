import React from "react";
import { grey } from "@material-ui/core/colors";
import { useFormContext } from "react-hook-form";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FinanceProgramFormValues } from "../../../types";
import { CheckboxField } from "../../../../../components/form";

export const ProposalSettings = () => {
  const classes = useStyles();

  const { control } = useFormContext<FinanceProgramFormValues>();

  return (
    <Box className={classes.container}>
      <Box className={classes.panel}>
        <Typography component="span" variant="subtitle2">
          Proposal Settings
        </Typography>
      </Box>

      <Box className={classes.form}>
        <CheckboxField control={control} name="displaySettings.productDetails" label="Show Product Details" />

        <CheckboxField control={control} name="displaySettings.productPrice" label="Show Product Price" />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
  panel: {
    padding: "8px",
    display: "flex",
    borderRadius: "2px",
    alignItems: "center",
    backgroundColor: grey["A100"],
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
});
